import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Utility from "../../utility";
import ComingSoon from "../ComingSoon/ComingSoon";
import * as Delivery from "../../models/delivery.model";

function BookDelivery(props: React.PropsWithChildren<RouteComponentProps>) {
  useEffect(() => {
    if (!Utility.isLoadedByIframe()) {
      props.history.push("/");
    }

    const queryParamString = props.location.search.substring(1);
    const request: Delivery.BookDeliveryPayload = Utility.queryParamsToJSON(
      queryParamString
    );

    book(request).then((response) => {
      window.parent.postMessage(response, "*");
    });
  }, [props]);

  return <ComingSoon />;
}

async function book(request: Delivery.BookDeliveryPayload) {
  const headers = new Headers();
  headers.set("x-api-key", !!request.appId ? request.appId : "");
  headers.set("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(request),
  };
  const requestURL = `${process.env.REACT_APP_QUIVER_SERVICE_BASEPATH}${process.env.REACT_APP_QUIVER_BOOK_DELIVERY_PATH}`;
  const response = await fetch(requestURL, requestOptions);
  if (response.status !== 200) {
    return {
      error: `Failed to communicate with Quiver service. Received ${response.status}.`,
    };
  }

  return await response.json();
}

export default withRouter(BookDelivery);
