import "./ComingSoon.scss";
import quiverLogo from "../../assets/quiver.png";

export default function ComingSoon() {
  return (
    <div className="app">
      <div className="app__bg-bg"></div>
      <div className="app__bg-fg"></div>

      <div className="app__content">
        <div className="app__img-container">
          <img src={quiverLogo} alt="Quiver" className="app__img" />
        </div>

        <div className="app__content-subtitle">
          <span>is coming soon . . .</span>
        </div>
      </div>
    </div>
  );
}
