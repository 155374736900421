import ComingSoon from "../ComingSoon/ComingSoon";
import { useEffect } from "react";
import * as Delivery from "../../models/delivery.model";
import * as Utility from "../../utility";
import { RouteComponentProps, withRouter } from "react-router-dom";

function Ping(props: React.PropsWithChildren<RouteComponentProps>) {
  useEffect(() => {
    if (!Utility.isLoadedByIframe()) {
      props.history.push("/");
    }

    window.addEventListener("message", (event) => {
      const pingRequest: Delivery.PingRequest = event.data;
      if (!pingRequest.appId) {
        window.parent.postMessage(
          { error: "Bad Request: no appId in request." },
          "*"
        );
      } else {
        ping(pingRequest).then((response) => {
          window.parent.postMessage(response, "*");
        });
      }
      window.removeEventListener("message", () => {});
    });
    return;
  }, [props]);

  return <ComingSoon />;
}

async function ping(request: Delivery.PingRequest) {
  const headers = new Headers();
  headers.set("x-api-key", !!request.appId ? request.appId : "");
  const requestOptions = {
    method: "GET",
    headers,
  };

  const requestURL = `${process.env.REACT_APP_QUIVER_SERVICE_BASEPATH}${
    process.env.REACT_APP_QUIVER_PING_PATH
  }?timestamp=${Date.now()}`;

  const response = await fetch(requestURL, requestOptions);
  if (response.status !== 200) {
    return {
      error: `Failed to communicate with Quiver service. Received ${response.status}.`,
    };
  }

  return {};
}

export default withRouter(Ping);
