import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Utility from "../../utility";
import * as Delivery from "../../models/delivery.model";
import ComingSoon from "../ComingSoon/ComingSoon";

function ReserveDelivery(props: React.PropsWithChildren<RouteComponentProps>) {
  useEffect(() => {
    if (!Utility.isLoadedByIframe()) {
      props.history.push("/");
    }

    const queryParamString = props.location.search.substring(1);
    const request: Delivery.ReserveDeliveryPayload = Utility.queryParamsToJSON(
      queryParamString
    );

    reserve(request).then((response) => {
      window.parent.postMessage(response, "*");
    });
  }, [props]);

  return <ComingSoon />;
}

async function reserve(request: Delivery.ReserveDeliveryPayload) {
  const headers = new Headers();
  headers.set("x-api-key", !!request.appId ? request.appId : "");
  headers.set("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers,
    body: JSON.stringify(request),
  };

  const requestURL = `${process.env.REACT_APP_QUIVER_SERVICE_BASEPATH}${process.env.REACT_APP_QUIVER_RESERVE_DELIVERY_PATH}`;
  const response = await fetch(requestURL, requestOptions);
  if (response.status !== 200) {
    return {
      error: `Failed to communicate with Quiver service. Received ${response.status}.`,
    };
  }

  return response.json();
}

export default withRouter(ReserveDelivery);
