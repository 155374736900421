import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import Ping from "./components/Ping/Ping";
import ReserveDelivery from "./components/ReserveDelivery/ReserveDelivery";
import BookDelivery from "./components/BookDelivery/BookDelivery";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/" exact component={App} />
      <Route path="/ping" exact component={Ping} />
      <Route path="/reserve" exact component={ReserveDelivery} />
      <Route path="/book" exact component={BookDelivery} />
      <Redirect to="/" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);

reportWebVitals();
